<template>
  <!--  保险类型-->
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>保险列表</el-breadcrumb-item>
      <el-breadcrumb-item>出险登记</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    内容开始-->
    <el-card class="box-card">
      <el-button type="primary" @click="opentan('add')">添加信息</el-button>
      <!--    列表开始-->
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="auto_number" label="出险车辆"></el-table-column>
        <el-table-column prop="auto_type_name" label="车辆类型"></el-table-column>
        <el-table-column prop="get_current_org.node_name" label="所属项目"></el-table-column>
        <el-table-column prop="happen_time" label="事故发生时间"></el-table-column>
        <el-table-column prop="cate_name" label="出险种类"></el-table-column>
        <el-table-column prop="get_losses_users[0].user_name" label="驾驶员"></el-table-column>
        <el-table-column prop="losses_type_name" label="我方责任占比"></el-table-column>
        <el-table-column prop="status_name" label="填写状态"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editfun(scope.row.id)"></el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页-->
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!--  弹窗内容-->
    <dia :dialogVisible="dialogVisible" :isAddorEdit="isAddorEdit" :infoObj="infoObj" @handleClose="handleClose" @update="getLossess" />
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import dia from './components/registerDialog.vue'
import { getLosses, infoLosses, delLosses } from '@/api/car'

export default {
  components: {
    Fenye,
    dia
  },
  data() {
    return {
      isAddorEdit: '',
      dialogVisible: false, // 弹窗控制
      infoObj: {},
      // 验证规则
      drules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      // 列表数据
      tableData: []
    }
  },
  mounted() {
    this.getLossess()
  },
  methods: {
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.getLossess()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.getLossess()
    },
    async editfun(id) {
      const { data } = await infoLosses({ id: id })
      console.log(data, 'data')
      if (data.code !== 200) {
        return this.$message.error('获取详情失败')
      }
      // let obj = JSON.parse(JSON.stringify(data.data))
      let obj = data.data
      obj.id = id
      this.infoObj = JSON.parse(JSON.stringify(obj))

      // 打开弹窗
      this.opentan('edit')
    },
    delfun(id) {
      // 删除
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data } = await delLosses({ id: id })
          console.log(data, 'data')
          if (data.code !== 200) {
            return this.$message.error('删除失败')
          }
          this.$message.success('删除成功')
          this.getLossess()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 打开弹窗
    opentan(type) {
      this.dialogVisible = true
      this.isAddorEdit = type
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
    },
    // 请求区ლ(´ڡ`ლ)
    // 获取品牌
    async getLossess() {
      const { data } = await getLosses(this.pageData)
      // this.getanpostfun(data, '获取')
      // this.tableData = data.data.list
      let arr = data.data.list
      if (arr.length === 0) {
        return
      }
      arr.map(item => {
        if (item.status === 0) {
          item.status_name = '未完成'
        } else {
          item.status_name = '已完成'
        }
        item.cate_name = []
        item.get_ins_company.map(item1 => {
          item.cate_name.push(item1.ins_type_name)
        })
        item.cate_name = item.cate_name.toString()
      })
      this.tableData = JSON.parse(JSON.stringify(arr))
      this.pageData.count = data.data.count
      this.pageData.page = Number(data.data.page)
    },

    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        this.handleClose()
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
<style scoped></style>
